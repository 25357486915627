.ProductRecall {
    &-LogoContainer {
        max-width: 80px;
    }

    &-TitleMain {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        @include mobile {
            align-items: flex-start;
        }
    }

    &-Icon {
        display: inline-block;
        width: 40px;
        height: 40px;
        background-color: rgb(1, 126, 1);
        border-radius: 50%;
        margin-right: 2.4rem;
        flex-shrink: 0;

        @include mobile {
            margin-top: 0.8rem;
        }

        &::before {
            content: '';
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            background-image: url(https://larryvsharry.com/media/icons/checkmark.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: 70%;
        }

        &_type {
            &-alert {
                background: rgb(245, 201, 6);

                &::before {
                    background-image: url(https://larryvsharry.com/media/icons/exclamation.svg);
                }
            }

            &-fail {
                background: var(--primary-base-color);

                &::before {
                    background-image: url(https://larryvsharry.com/media/icons/exclamation.svg);
                }
            }
        }
    }

    &-DescriptionContainer {
        max-width: 600px;
    }

    &-InstructionListItem {
        font-size: 2.4rem;
        padding-left: 6.0rem;
        color: var(--secondary-dark-color);
        margin-bottom: 1.2rem;

        &::before {
            top: 0;
        }
    }

    &-InstructionDesc {
        font-size: 2.4rem;
        color: var(--secondary-dark-color);
        max-width: 600px;
    }

    &-FormContainer {
        max-width: 500px;

        input {
            width: 100%;
        }
    }

    &-LinkWrapper {
        margin-bottom: .6rem;
    }
}