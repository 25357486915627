/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';

.NavigationTabs {
    width: 100vw;
    border-top: none;

    &-Nav {
        padding: 0;
    }

    &-Icon {
        &_isActive {
            &::before,
            &::after {
                --header-color: var(--white);
            }
            
            .Header-MinicartItemCount {
                background-color: var(--white);
                color: var(--primary-base-color)!important;
            }
        }
        
        
    }

    &-MenuOverlay {
        .NavigationTabs-Icon_isActive + & {
            background: var(--primary-base-color);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
        } 
    }

    .isCheckoutPage & {
        &_isHidden {
            &_undefined {
                display: none;
            }
        }
    }

}
