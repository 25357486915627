/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';

.ProductLinks {
    margin-top: 0;

    &-Wrapper {
        max-width: var(--narrow-content-width);
    }

    &-Title {
        font-size: var(--h3-font-size);
        font-weight: var(--header-font-weight);
        text-transform: none;
    }

    &-List {
        grid-template-columns: repeat(4, 1fr);

        @include before-desktop {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}
