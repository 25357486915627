/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';

:root {
    --bike-builder-footer-height: 80px;

    @include before-desktop {
        --bike-builder-footer-height: 50px;
    }
}

.BikeBuilderFooter {
    height: var(--bike-builder-footer-height);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
    background: var(--grey-background-dark);
    color: var(--body-content-color-light);
    font-size: var(--h5-font-size);

    @include before-desktop {
        bottom: var(--header-nav-height);
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        background: var(--grey-background-dark);
    }

    > * {
        z-index: 2;
    }

    &-BackButton,
    &-NextButton,
    .ProductActions-AddToCart {
        &.Button {
            height: 100%;
            text-align: center;
            width: 200px;
            border-radius: 0;

            @include before-desktop {
                width: var(--bike-builder-footer-height);
                min-width: var(--bike-builder-footer-height);
                max-width: var(--bike-builder-footer-height);

                background-image: url(https://www.larryvsharry.com/pub/media/hoeks-pwa/arrow-left.svg);
                font-size: 0;
                background-repeat: no-repeat;
                background-position: center;
                background-size: 1.6rem;
            }
        }
    }

    &-NextButton {
        &.Button {
            @include before-desktop {
                transform: rotateZ(180deg);
            }
        }
    }

    .ProductActions-AddToCart {
        &.Button {
            @include before-desktop {
                background-image: url(https://www.larryvsharry.com/pub/media/hoeks-pwa/cart.svg);
                background-size: 34px;
            }
        }
    }

    .EstimatedDate {
        &-DateContainer {
            margin-left: 3.6rem;
            display: flex;
            // flex-direction: row;
            flex-direction: column;
            font-size: 1.4rem;

            // @media screen and (max-width: 1350px) {
            //     flex-direction: column;
            // }

            @media screen and (max-width: 1200px) {
                margin-left: 1.2em;
            }

            @include before-desktop {
                margin-left: 2.4rem;
            }

            @include mobile {
                display: none;
            }

            // > div:not(:last-child) {
            //     margin-right: 2.4rem;

            //     @media screen and (max-width: 1350px) {
            //         margin-left: 0;
            //     }
            // }
        }

        &-RequiredDateLabel,
        &-OptionalDateLabel {
            font-weight: 600;
        }
    }

    &-ProductName {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        @include mobile {
            position: relative;
            margin: 0 auto;
            left: unset;
            transform: none;
        }

        > span {
            font-size: 2rem;
            font-weight: var(--header-font-weight);

            @include mobile {
                font-size: 1.6rem;
            }
        }
    }

    .ProductPrice {
        margin-left: auto;
        font-size: var(--h5-font-size);
        color: var(--body-content-color-light);

        @include before-desktop {
            margin-right: 2.4rem;
        }

        @include mobile {
            margin: 0 auto;
            font-size: 1.4rem;
        }
    }

    &-SummaryButton {
        &.Button:not([disabled]) {
            background: transparent;
            color: var(--body-content-color-light);
            border: 1px solid var(--body-content-color-light);
            margin-left: 3.6rem;
            margin-right: 3.6rem;

            @media screen and (max-width: 1200px) {
                margin-left: 1.2rem;
                margin-right: 1.2rem;
            }

            @include before-desktop {
                position: fixed;
                bottom: calc(var(--bike-builder-footer-height) + var(--header-nav-height) + 10px);
                right: 10px;
                height: 50px;
                width: 50px;
                display: block;
                margin: 0;
                border: none;
                color: var(--body-content-color-light);
                z-index: 5;
                background: var(--primary-base-color);
                background-image: url(https://www.larryvsharry.com/pub/media/hoeks-pwa/summary.svg);
                font-size: 0;
                background-repeat: no-repeat;
                background-position: center;
                background-size: 55%;
                padding: 0;
                border-radius: 50%;
                box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, .25);
            }

            &:hover,
            &:focus,
            &:active {
                background-color: var(--body-content-color-light);
                color: var(--body-heading-color);
                border-color: var(--body-content-color-light);

                @include before-desktop {
                    color: var(--body-content-color-light);
                    background-color: var(--primary-base-color);
                }
            }
        }
    }

    &-CloseSummaryButton {
        &.Button:not([disabled]) {
            font-size: 0;
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            height: 50px;
            width: 50px;
            padding: 0;
            border-radius: 0;

            &::after {
                content: '';
                background-image: url(https://www.larryvsharry.com/pub/media/hoeks-pwa/close.svg);
                background-position: center;
                background-size: 20px;
                background-repeat: no-repeat;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    &-PrintSummaryButton {
        &.Button:not([disabled]),
        &.Button:not([disabled]):hover,
        &.Button:not([disabled]):focus,
        &.Button:not([disabled]):active {
            position: absolute;
            top: 0;
            right: 50px;
            height: 50px;
            width: 50px;
            padding: 0;
            background: transparent;
            border-color: transparent;
            font-size: 0;
            background-image: url(https://www.larryvsharry.com/pub/media/hoeks-pwa/print-hvid.svg);
            background-size: 24px;
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    &-SaveConfigButton {
        &.Button:not([disabled]),
        &.Button:not([disabled]):hover,
        &.Button:not([disabled]):focus,
        &.Button:not([disabled]):active {
            position: absolute;
            top: 0;
            right: 100px;
            height: 50px;
            width: 50px;
            padding: 0;
            background: transparent;
            border-color: transparent;
            font-size: 0;
            background-image: url(https://www.larryvsharry.com/pub/media/hoeks-pwa/save.svg);
            background-size: 24px;
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    &-Summary {
        position: fixed;
        bottom: 80px;
        right: 200px;
        background: #3c3c3c;
        width: calc(100% - 4.8rem);
        max-width: 400px;
        height: 90vh;
        max-height: 600px;
        transform: translateY(0);
        z-index: -1 !important;
        transition: all ease .6s;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, .5);
        // background-image: url(https://www.larryvsharry.com/pub/media/hoeks-pwa/victory-4.png);
        // background-size: auto 80%;
        // background-position: bottom right;
        // background-repeat: no-repeat;
        overflow: hidden;
        border-top-left-radius: var(--theme-border-radius);
        border-top-right-radius: var(--theme-border-radius);

        @include before-desktop {
            position: fixed;
            z-index: 10 !important;
            height: calc(100vh - var(--header-nav-height));
            max-height: calc(90vh - var(--header-nav-height));
            width: 100vw;
            max-width: 500px;
            right: 0;
            bottom: var(--header-nav-height);
            border-top-right-radius: 0;
        }

        @include mobile {
            max-width: 100vw;
            border-top-left-radius: 0;
        }

        &_isHidden {
            transform: translateY(650px);

            @include before-desktop {
                transform: translateY(100vh);
            }
        }
    }

    &-SummaryContent {
        height: 100%;
        width: 100%;

        @media print {
            padding: 50px;
            padding-top: 70px;
        }

        .BikeBuilderFooter-SummaryPrintBadge {
            position: absolute;
            content: '';
            width: 80px;
            height: 80px;
            top: 30px;
            right: 50px;
            z-index: 1;
            display: none;

            @media print {
                display: block;
            }
        }

        .BikeBuilderFooter-SummaryPrintBadgeUnderlay {
            position: absolute;
            content: '';
            width: 100%;
            height: auto;
            bottom: 0;
            right: -20%;
            display: none;
            transform: rotateZ(15deg);
            z-index: -1;
            opacity: .025;

            @media print {
                display: block;
            }
        }

        .BikeBuilderFooter-SummaryPrintBullittLogo {
            position: absolute;
            content: '';
            width: 300px;
            height: auto;
            bottom: 50px;
            left: 50%;
            transform: translateX(-50%);
            display: none;

            @media print {
                display: block;
            }
        }
    }

    &-SummaryTitle {
        color: var(--body-content-color-light);
        margin-bottom: 0;
        height: 50px;
        line-height: 50px;
        padding: 0 100px 0 2.4rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        background-color: var(--grey-background-dark);

        @media print {
            color: var(--black);
            font-weight: 600;
            padding: 0;
            border-bottom: 1px solid grey;
            padding-bottom: 1.2rem;
            height: auto;
            line-height: auto;
        }

        // &::after {
        //     position: absolute;
        //     left: 0;
        //     bottom: 0;
        //     height: 2px;
        //     width: 100px;
        //     background: var(--primary-base-color);
        //     content: '';
        // }
    }

    &-SummaryProductTitle {
        display: none;

        @media print {
            display: block;
            color: var(--black);
            font-size: 3rem;
            font-weight: 500;
        }
    }

    &-SummaryPrice {
        display: none;

        @media print {
            display: block;
            color: var(--black);
            float: right;
            padding-top: 1.2rem;
        }

        &Label {
            display: inline-block;
            font-size: 2rem;
            height: 4rem;
            line-height: 4rem;
            vertical-align: middle;
            margin-right: 5px;
        }

        .ProductPrice {
            display: inline-block;
            height: 4rem;
            line-height: 4rem;
            vertical-align: middle;
        }
    }

    &-SummaryPrintNoticeContainer {
        display: none;
        flex-direction: column;
        margin-top: 1.8rem;

        @media print {
            display: flex;
        }
    }

    &-SummaryPrintNotice {
        display: block;
        margin-bottom: .6rem;
    }

    &-ConfigurationUrl {
        display: block;
        padding: 1.2rem 2.4rem;

        .BikeBuilderFooter-SummarySection {
            margin-bottom: unset;
        }

        .BikeBuilderFooter-SummaryItem {
            width: 100%;
        }
    }

    &-SummaryItems {
        display: block;
        overflow-y: scroll;
        height: calc(100% - 50px);
        padding: 1.2rem 2.4rem;

        @include mobile {
            height: calc(100% - 150px);
        }

        @media print {
            height: auto;
            padding: 0 0 0 1.2rem;
            overflow: hidden;
            border-bottom: 1px solid grey;
            padding-bottom: 2.4rem;
        }

        &::-webkit-scrollbar {
            width: 1.2rem;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 4px rgba(0, 0, 0, .3);
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgb(207, 207, 207);
            outline: none;
        }
    }

    &-SummarySection {
        margin-bottom: 3.6rem;
    }

    &-SummarySectionTitle {
        font-size: 2rem;
        display: block;
        border-bottom: 1px solid #656565;
        margin-bottom: 1.2rem;
        padding-bottom: .6rem;
        font-weight: 500;
    }

    &-SummaryItem {
        margin-bottom: 1.2rem;
        font-size: 1.4rem;
        display: block;
    }

    &-SummaryItemLabel {
        font-weight: 600;
    }

    &-SummaryDateContainer {
        position: absolute;
        bottom: 0;
        border-top: 1px solid rgba(255, 255, 255, .2);
        width: 100%;
        padding: 0 2.4rem;
        display: none;
        height: 100px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        @include mobile {
            display: flex;
        }

        @media print {
            display: none;
        }
    }
}
