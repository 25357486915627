/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';

:root {
    --menu-desktop-height: var(--header-nav-height);

    @include before-desktop {
        --menu-item-height: 60px;
        --menu-select-height: 40px;
    }

    @include mobile {
        --menu-item-height: 40px;
        --menu-select-height: 30px;
    }
}

// This is not supposed to be here, but it is too extensive to overwrite the page for this one chage
.MenuPage {
    @include before-desktop {
        background: #fff;
    }
}

.Menu {
    &-MenuWrapper {
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;

        @include before-desktop {
            padding: 0rem;

            .StoreSwitcher,
            .CurrencySwitcher {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
            }

            .Field {
                &_type {
                    &_select {
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                }
            }

            .FieldSelect {
                &-Select {
                    padding: 0 2.4rem;
                    height: var(--menu-select-height);
                    background: var(--primary-base-color);
                    font-weight: 500;
                    font-size: 1.6rem;
                    border: none;
                    color: var(--white);
                    direction: ltr;

                    option {
                        direction: ltr;
                    }
                }

                &::after {
                    right: 2.4rem;
                    border-color: var(--white);
                }
            }
        }

        @include mobile {
            .FieldSelect {
                &-Select {
                    padding: 0;
                    padding-left: 1.2rem;
                    padding-right: 1.2rem;
                    font-size: 1.2rem;
                    font-weight: 400;
                }

                &::after {
                    right: 1.2rem;
                }
            }
        }

        @media screen and (min-width: 1025px) and (max-width: 1050px) {
            padding-left: calc(var(--header-logo-width) + 1.2rem + 1.2rem);
        }

        @include desktop {
            padding-left: calc(var(--header-logo-width) + 2.4rem + 2.4rem);
        }

        li {
            &:nth-last-child(-n+2) {
                @include desktop {
                    display: none;
                }
            }
        }
    }

    &-SelectFields {
        @include before-desktop {
            order: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 2.4rem 9.6rem 0 9.6rem;

            > div {
                width: 50%;
                border: 1px solid var(--white); 

                .Field {
                    width: 100%;
                }

                &:first-child {
                    margin-right: 1.2rem;
                }
            }

            > .StoreSwitcher {
                order: 1;
            }

            > .CurrencySwitcher {
                order: 2;
            }
        }

        @include mobile {
            padding: 1.2rem 4.8rem 0 4.8rem;
        }
    }

    &-ItemList {
        @include before-desktop {
            order: 2;
            flex: 1;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            padding-bottom: calc(var(--menu-select-height) * 2);
        }
    }

    &-MainCategories {
        max-width: 100%;
        padding: {
            left: 0;
            right: 0;
        };
        
        @include before-desktop {
            min-height: calc(100vh - var(--header-total-height) - var(--navigation-tabs-height));
            background: var(--primary-base-color);
            justify-content: center;
        }
    }

    &-Item {
        @media screen and (max-width: 1300px) {
            padding: 0 2.4rem 0 0;
        }

        @include before-desktop {
            padding: 0;
            margin-top: 0;
            margin-bottom: 0;

            .Menu-ItemFigure_type_main {
                background-color: var(--primary-base-color);
                border: none;
                color: var(--white);
                height: var(--menu-item-height);
                padding: 0 9.6rem;
                
                .Menu-ItemCaption_type_main {
                    font-weight: var(--header-font-weight);
                    font-size: 2.6rem;
                    font-weight: 400;
                }
            }

            // &:nth-last-child(1) .Menu-ItemFigure_type_main {
            //     background: var(--button-background);
            //     border-color: var(--button-background);
            //     color: var(--white);
            // }

            // &:nth-last-child(2) .Menu-ItemFigure_type_main {
            //     background: transparent;
            //     color: var(--body-heading-color);
            //     border: 1px solid var(--button-background);
            // }
        }

        @include mobile {
            .Menu-ItemFigure_type_main {
                .Menu-ItemCaption_type_main {
                    font-size: 2rem;
                }
            }
        }

        @include mobile {
            .Menu-ItemFigure_type_main {
                padding: 0 4.8rem;
            }
        }
    }

    &-ItemFigure {
        &_type {
            &_main {
                text-transform: none;
            }
        }
    }

    &-ItemCaption {
        &_type {
            &_main {
                @include after-mobile {
                    font-size: 1.6rem;
                    font-weight: 500;
                }

                @include desktop {
                    color: var(--body-heading-color);
                }
            }
        }
    }

    &-CompareLinkWrapper {
        display: none;
    }

    &-Promotion {
        display: none;
    }
}
