/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';

.EstimatedDate {
    &-RequiredDateValue,
    &-OptionalDateValue {
        font-weight: 700;
        line-height: 1;
        font-size: var(--h5-font-size);
        // color: var(--body-content-color-light);
    }
    &-OptionalDate {
        @include desktop {
            margin-top: 5px;
        }
    }

    @at-root .ProductPage_isBikeBuilder & {
        position: absolute;
        top: 1.2rem;
        background: var(--button-hover-background);
        left: 0;
        width: 100%;
        height: auto;
        border-top-left-radius: var(--theme-border-radius);
        border-top-right-radius: var(--theme-border-radius);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        text-align: center;
        padding: 0.4rem 0.2rem;
        line-height: 1.2;
    }
}