/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../style/abstract/variables';
@import '../../../style/abstract/media';
@import '../../../style/abstract/button';
@import '../../../style/abstract/loader';
@import '../../../style/abstract/icons';
@import '../../../style/abstract/parts';
@import '../../../style/abstract/theme-abstract';

.plus-button, .plus-sign{
    pointer-events: none;
}
.BlogPostCard {
    // &:not(:last-child) {
    //     &::after {
    //         content: '';
    //         position: absolute;
    //         bottom: calc((var(--blog-grid-gap) / 2) * -1);
    //         width: 100%;
    //         height: 1px;
    //         background: rgba(0, 0, 0, .07);
    //     }
    // }

    .plus-button{
        position: absolute;
        bottom:  3.6rem;
        left: 3.6rem;
        display: block;
        content: "";
        height: 38px;
        width: 38px;
        border-radius: 50%;
        background-color: #D7282E;
        z-index: 25;
        display: none;
    }

    .plus-sign{
        position: absolute;
        display: grid;
        content: "+";
        // bottom:  2.7rem;
        width: 38px;
        height: 38px;
        bottom:  3.6rem;
        left: 3.6rem;
        font-size: 3.6rem;
        color: white !important;
        z-index: 30;
        align-content: center;
        justify-content: center;
    }
           &-Details{
            padding: 3.6rem !important;
               a{
               &:hover{
                   text-decoration: none !important;
               }
            }
            position: relative;
           
           
           }
           &-TextTitle{
            color: var(--cms-theme-color-1-text);
           }
           &-DescriptionWrapper{
               font-size: var(--p-font-size);
            //    margin-bottom: 2.4rem; 

            > *:last-child {
                margin-bottom: 0;
            }
           }
    &.BlogPostCard_isFeatured {
        grid-column: span 3;
        margin: 5rem 0;
        @include mobile {
            grid-column: span 1;
        }
        @include desktop{
        .plus-button{
            left: unset;
            bottom: calc(3.6rem + 19px);
            right:calc(16.5% - 19px );
        }
       .plus-sign{
        left: unset;
        bottom: calc(3.6rem + 19px);
        right:calc(16.5% - 19px );
        
        }
    }
    .Image{
        border-radius: 1em;
        overflow:  hidden;
        margin-right: 15px;
        
    }
    
        .BlogPostCard{
            @include desktop{
                &-InnerWrapper{
                    display: grid;
                    grid-template-columns: 2fr 1fr;
                    grid-gap: 15px;
                    box-shadow: none;
                }
                    &-Details{
                    text-align: center;
                    padding: 3.6rem 3.6rem  !important;
                    align-content: center;
                    display: grid;
                    grid-template-columns: 1fr;
                }
            }
            @include mobile {
                &-InnerWrapper{
                    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);
                }
            }
        
    }
       
    }

    &-MainLink{
        position: relative;
        &:hover{
            text-decoration: none !important;
        }
        & > div {
            color: black !important;
        }
    }
    &-ContentWrapper {
        height: 100%;
    }

    &-InnerWrapper {
        height: 100%;
        background: #fff;
        box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);
        overflow: hidden;
    border-radius: 1em;
        
    }

    &-ImageWrapper {
        display: block;
    }

    &-Image {
        height: 0;
        padding-bottom: 350px;
        > img {
            display: block;
            position: absolute;
        }
    }

    &-Details {
        padding: 2.4rem;
    }

    &-DescriptionWrapper {
        max-height: 1000px;
    }
}
