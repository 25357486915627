/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';

:root {
    --bundle-section-height: 60px;
}

.BundleSections {
    height: var(--bundle-section-height);
    border-bottom: 1px solid var(--primary-divider-color);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @include before-desktop {
        justify-content: flex-start;
    }

    &-SectionItems {
        height: var(--bundle-section-height);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: calc(100% - 300px);

        @media screen and (max-width: 1200px) {
            justify-content: flex-start;
            overflow-x: scroll;
        }

        @include before-desktop {
            width: 100%;
        }
    }

    &-BackToShop,
    &-ToCheckout {
        position: absolute;
        top: 0;
        left: 0;
        height: calc(100% - 1px);
        width: 150px;
        background: var(--white);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: var(--body-heading-color);
        border-right: 1px solid var(--primary-divider-color);
        font-size: 1.4rem;
        z-index: 5;

        @include before-desktop {
            display: none;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: calc(100% + 1px);
            width: 60px;
            height: calc(100% - 5px);
            display: block;
            background: rgb(255, 255, 255);
            background:
                linear-gradient(
                    90deg,
                    rgba(255, 255, 255, 1) 0%,
                    rgba(255, 255, 255, .04) 100%
                );
            pointer-events: none;
            z-index: -1;
        }

        &:hover {
            text-decoration: none;
            color: var(--link-color);
        }
    }

    &-ToCheckout {
        left: unset;
        right: 0;
        border-left: 1px solid var(--primary-divider-color);

        &::after {
            left: unset;
            right: calc(100% + 1px);
            background:
                linear-gradient(
                    -90deg,
                    rgba(255, 255, 255, 1) 0%,
                    rgba(255, 255, 255, .04) 100%
                );
            pointer-events: none;
        }

        &_isSomethingInCart,
        &_isSomethingInCart:hover,
        &_isSomethingInCart:focus,
        &_isSomethingInCart:active {
            background: var(--button-background);
            text-decoration: none;
            color: var(--button-color);
            font-weight: var(--button-font-weight);
            transition: all ease .3s;

            &:hover {
                background: var(--button-hover-background);
                color: var(--button-hover-color);
            }
        }
    }

    &-Section {
        height: var(--bundle-section-height);
        line-height: var(--bundle-section-height);
        padding: 0;
        display: block;
        font-size: 1.4rem;
        border-bottom: 0 solid transparent;
        cursor: pointer;
        transition: all ease .3s;
        text-align: center;
        width: 180px;
        min-width: 180px;
        max-width: 180px;
        white-space: nowrap;

        &::before {
            content: '1. ';
        }

        &:nth-child(2) {
            &::before {
                content: '2. ';
            }
        }

        &:nth-child(3) {
            &::before {
                content: '3. ';
            }
        }

        &:nth-child(4) {
            &::before {
                content: '4. ';
            }
        }

        &:nth-child(5) {
            &::before {
                content: '5. ';
            }
        }

        &:nth-child(6) {
            &::before {
                content: '6. ';
            }
        }

        &:nth-child(7) {
            &::before {
                content: '7. ';
            }
        }

        &:nth-child(8) {
            &::before {
                content: '8. ';
            }
        }

        &:nth-child(9) {
            &::before {
                content: '9. ';
            }
        }

        &:nth-child(10) {
            &::before {
                content: '10. ';
            }
        }

        &_isActive {
            color: var(--primary-base-color);
            border-bottom: 5px solid var(--primary-base-color);
        }
    }
}
