/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../style/abstract/variables';
@import '../../../style/abstract/media';
@import '../../../style/abstract/button';
@import '../../../style/abstract/loader';
@import '../../../style/abstract/icons';
@import '../../../style/abstract/parts';
@import '../../../style/abstract/theme-abstract';

.BlogRecentPosts {
    
    &-PostTitle {
        font-weight: var(--header-font-weight);
    }
     
    &-PostDescription{
        color:  black;
    }

    &-PostCard {
        display: block;
        padding: 0;
        flex-direction: column;
        border: none !important;
        border-radius: 1em;
    overflow: hidden;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);

        .Image {
            object-fit: cover;
            height: 0;
            padding-bottom: 350px;
            display: block;
        }

        .Image-Image {
            object-fit: cover !important;
            position: absolute !important;
        }
    }
&-Wrapper{
    position: relative;
    .plus-button{
        position: absolute;
        bottom:  3.6rem;
        left: 3.6rem;
        display: block;
        content: "";
        height: 38px;
        width: 38px;
        border-radius: 50%;
        background-color: #D7282E;
        z-index: 25;
        
    }

    .plus-sign{
        position: absolute;
        display: grid;
        content: "+";
        // bottom:  2.7rem;
        width: 38px;
        height: 38px;
        bottom:  3.6rem;
        left: 3.6rem;
        font-size: 3.6rem;
        color: white !important;
        z-index: 30;
        align-content: center;
        justify-content: center;
    }
    a{
            text-decoration: none !important;
            h3{
                color: var(--link-color)
            };
    }
    .react-reveal{
        display: flex;
    flex-direction: column;
    }
}
    &-ImageWrapper {
        // flex-basis: 100%;
        width: 100%;
    }

    &-Date {
        position: relative;
        border: unset;
    }

    @at-root .BlogRecentPosts-Wrapper & {
        &-Wrapper {
            height: 100%;
            background: #fff;
            padding: 3.6rem 3.6rem 8rem;
        }
    }
}

.PostsDetails-Sidebar > .BlogRecentPosts-Wrapper {
    flex-basis: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
}
