/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../style/abstract/variables';
@import '../../../style/abstract/media';
@import '../../../style/abstract/button';
@import '../../../style/abstract/loader';
@import '../../../style/abstract/icons';
@import '../../../style/abstract/parts';
@import '../../../style/abstract/theme-abstract';

:root {
    --blog-grid-gap: 30px;
    --total-header-height: calc(var(--header-menu-height) + var(--header-height) + 1px);
}
.Blog-Video{
    height: 70vh !important;
    min-height: 500px !important;
}
.PostsListing {
    position: relative;
    background-color: white;
    padding-bottom: 92px;

    @include desktop {
        margin-top: -40px;
    }

    .ContentWrapper {
        padding-top: 50px;
    }

    &-Header {
        margin-top: -41px;
        height: 70vh;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: calc(var(--total-header-height) / 2);
        .cms-col.cms-col-4 {
            text-align: center;
            width: 100%;
        }

        &_page_career {
            .cms-col.cms-col-4 {
                text-align: left;
            }
        }

        &Subheading {
            font-size: 2rem;
            font-weight: normal;
        }

        h1 {
            font-size: 6rem;
            color: var(--cms-light-font-color);

            @include mobile {
                font-size: 4rem;
            }
        }

        p {
            font-size: 4rem;
            color: var(--cms-light-font-color);

            @include mobile {
                font-size: 2.4rem;
            }
        }
    }

    &-ColumnWrapper {
        grid-gap: 10px;
        padding-top: 50px;
        padding-bottom: 100px;
        display: grid;
        grid-template-columns: 1fr;
    }

    &-Grid {
        padding: 0;
        grid-template-columns: 1fr;
        grid-gap: var(--blog-grid-gap);
        flex: auto;
        @include tablet {
            grid-template-columns: repeat(3, 1fr);
        }

        @include desktop {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &-LoadMoreWrapper{
        @include desktop{
        position: absolute;
        bottom: -92px;
        left: 50%;
        transform: translateX(-50%);
    }
    @include tablet{
            position: absolute;
            bottom: -92px;
            left: 50%;
            transform: translateX(-50%);
    }
    }
}
