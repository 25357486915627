/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../style/abstract/variables';
@import '../../../../style/abstract/media';
@import '../../../../style/abstract/button';
@import '../../../../style/abstract/loader';
@import '../../../../style/abstract/icons';
@import '../../../../style/abstract/parts';
@import '../../../../style/abstract/theme-abstract';

.blog-slider{
  padding: 0 var(--cms-spacing-x1);
  h2{
    margin-bottom: 0;
    padding: 0 1.2rem;
  }
}
.RenderWhenVisible{
  .cms-slider{
    max-width: 1448px;
  margin: 0 auto;
  overflow: hidden;
  display: block;
  }
}
.slick-list{
  overflow: hidden;
}
.slick-slider{
  max-width: 1400px;
  margin: 0 auto;
}
.slick-track{
  display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 2.4rem 0;
    min-width: 100%;
}
.slick-slide{
  height: unset;
  padding: 0 2.4rem;
  display: block;
  & > div {
    height: 100%;
  }
  & .RenderWhenVisible {
    height: 100%;
  }
}
.BlogPostCardWidget{
  &-LinkWrapper{
    &:hover{
      text-decoration: none;
    }
  }
  &-Container{
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .15);
    border-radius: 1em;
    overflow: hidden;
    height: 100%;
  }
  &-Details{
    padding: 3.6rem 3.6rem 8rem !important;
    position: relative;
  }
  &-DescriptionWrapper{
    max-height: 1000px;
    font-size: var(--p-font-size);
    margin-bottom: 2.4rem;
  }
  &-ShortContent{
    color: black;
  }

}