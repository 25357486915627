/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';

:root {
    --footer-background-color: var(--theme-dark-blue);
    --footer-heading-color: var(--body-heading-color-light);
    --footer-content-color: var(--footer-content-color-light);
}

.Footer {
    $block: &;

    color: var(--footer-content-color);

    a {
        color: var(--footer-content-color);
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: var(--body-heading-color-light);
    }

    &-Content {
        background-color: var(--footer-background-color);
        background-position: left center;
        background-size: auto 100%;
        background-repeat: no-repeat;
        min-height: 0;

        @include desktop {
            background-image: url(https://www.larryvsharry.com/pub/media/hoeks-pwa/lvsh-grafik.png);
        }

        // @include before-desktop {
        //     background-size: 80% auto;
        //     background-position: right bottom;
        // }
    }

    &-Columns {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 6rem 3.6rem 7.8rem 3.6rem;


        @include tablet {
            padding: 6rem 4.8rem 10.8rem 4.8rem;
        }

        @include desktop {
            padding: 6rem 1.2rem 2.4rem;
        }

        .CmsBlock-Wrapper {
            width: 100%;
        }
    }

    &-ColumnContainer {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;

        @include before-desktop {
            flex-direction: column;
        }

        @include tablet {
            flex-direction: row;
            justify-content: stretch;
            flex-wrap: wrap;
        }
    }

    &-Column {
        width: 20%;
        max-width: 100%;

        @include before-desktop {
            width: 100%;
            text-align: center;
        }

        @include tablet {
            width: 50%;
            max-width: 50%;
            margin-bottom: 50px;
            text-align: left;
        }

        &:not(:last-of-type) {
            @include tablet {
                max-width: 50%;
                width: 50%;
            }
        }

        &:first-of-type {
            @include before-desktop {
            width: 100%;
            max-width: 100%;
            text-align: center;
            align-items: center;
            }

            .Image {
                @include before-desktop {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        
        }

        &_isFull {
            width: 100%;
            flex: 1;
        }

        &_isNewsletter {
            padding-left: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-top: 4.8rem;

            .NewsletterSubscription {
                .FieldForm-Fieldset {
                    .Field {
                        input {
                            @include desktop {
                                width: 400px;
                            }
                        }
                    }
                }
            }
        }

        p {
            margin-bottom: .6rem;
        }
    }

    &-ColumnContainerGrid {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 2.4rem;
        width: 100%;
        text-align: left;

        @include tablet {
            grid-template-columns: repeat(2, 1fr);
        }
        
        @include desktop {
            grid-template-columns: repeat(6, 1fr);
        }
    }

    &-ColumnGrid {
        margin-bottom: 4.8rem;

        &:last-child {
            @include mobile {
                margin-bottom: 2.4rem;
            }
        }

        @include tablet {

        }

        @include desktop {

        }

        &_type_logo {
            @include tablet {
                grid-row-start: 3;
                grid-column-start: 2;
            }
        }

        &_type_dealer {
            @include tablet {
                grid-row-start: 3;
                grid-column-start: 1;
            }
        }

        p {
            margin-bottom: .6rem;
        }
    }

    &-ColumnContainerFlex {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding: {
            top: 2.4rem;
            bottom: 2.4rem;
        };

        @include after-mobile {
            flex-direction: row;
        }

        &_type_mobileBackground {
            @include mobile {
                background-image: url(https://www.larryvsharry.com/pub/media/hoeks-pwa/lvsh-background-mobile.png);
                background-size: contain;
                background-position: left top;
            }
        }

        &_isDivider {
            @include after-mobile {
                border-top: 0.1rem solid var(--body-content-color);
            }
        }

        &_isLast {
            padding-bottom: 0;
        }
    }

    &-ColumnFlex {
        display: flex;
        flex-direction: column;

        @include tablet {
            width: 50%;
        }

        &_isRight {
            @include mobile {
                margin-top: 4.8rem;
            }

            @include tablet {
                margin-left: 2.4rem;
            }

            @include desktop {
                align-items: flex-end;
                flex-grow: 1;
            }
        }

        &_isHorizontal {
            flex-direction: row;
            
            & p {
                margin-left: 2.4rem;
                
                &:first-child {
                    margin-left: 0;
                }
            }
        }

        p {
            margin-bottom: 0;
        }
    }

    &-BecomeADealer {
        margin-top: 2.4rem;
    }

    &-Title {
        font-size: 3.6rem;
        margin-bottom: 1.8rem;
    }

    &-SocialContent {
        margin-top: 2.4rem;
        display: flex;
    }

    &-SocialButton {
        &:first-child {
            a {
                margin-left: 0;
            }
        }

        & a {
            display: block;
            position: relative;
            // The filter is useful if you need to get a black icon close to the color: #D7282E
            //filter: invert(19%) sepia(77%) saturate(3744%) hue-rotate(347deg) brightness(90%) contrast(86%);
            
            line-height: 0;
            padding: 2.4rem;
            margin-left: 2.4rem;

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 4.6rem;
                height: 4.6rem;
                outline: 0.1rem solid #D7282E;
                border-radius: var(--theme-border-radius-button);
            }

            & .Image {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 2.6rem;
                height: auto;
                & .Image-Image {
                    object-fit: contain;
                    object-position: center;
                }
            }
        }
    }

    & #{$block}-ButtonHollow {
        border-color: var(--footer-content-color);
    }

    &-MailchimpSignupContainer {
        & .cms-mailchimp-form {
            @include after-mobile {
                width: max-content;
            }

            @include mobile {
                margin-top: 0;
            }

            & input {
                width: 100%;
                font-size: 1.6rem;

                &[type="submit"] {
                    font-weight: 500;
                }
            }
    
            & .mc-field-group {
                @include mobile {
                    width: 100%;
                }
            }
        }
    }

    & .Button {
        color: var(--white);
    }

    &-Expandable {
        &Main {
            position: relative;

            & input {
                position: absolute;
                top: -5.4rem;
                right: 0;
                padding: 1.8rem !important;
                width: 100%;
                min-width: auto;
                min-height: auto;
                cursor: pointer;
                border: 0;
                background-color: transparent;

                @include after-mobile {
                    display: none;
                }

                &:focus {
                    border: 0;
                }

                &:checked {
                    & ~ .Footer-ExpandableMenu {
                        transition-property: opacity, visibility;
                        transition-duration: 500ms;
                        opacity: 1;
                        visibility: visible;
                        height: initial;
                    }

                    & ~ .Footer-ExpandableToggle {
                        &::before, &::after {
                            transform: translate(-50%, -50%) rotate(45deg);
                        }
                    }
                }
            }
        }

        &Toggle {
            
            position: absolute;
            top: -4.8rem;
            right: 0;
            padding: 1.0rem !important;
            pointer-events: none;
            border-radius: var(--theme-border-radius-button);
            border: 2px solid var(--primary-base-color);

            @include after-mobile {
                display: none;
            }

            &::before {
                content: '';
                position: absolute;
                width: 0.2rem;
                height: 1.2rem;
                background-color: var(--primary-base-color);
                top: 50%;
                left: 50%;
                transition-property: transform;
                transition-duration: 500ms;
                transform: translate(-50%, -50%);
            }

            &::after {
                content: '';
                position: absolute;
                width: 1.2rem;
                height: 0.2rem;
                background-color: var(--primary-base-color);
                top: 50%;
                left: 50%;
                transition-property: transform;
                transition-duration: 500ms;
                transform: translate(-50%, -50%);
            }
        }
    
        &Menu {
            transition-property: opacity, visibility;
            transition-duration: 500ms;
            overflow: hidden;
            height: 0;
            opacity: 0;
            visibility: hidden;

            @include after-mobile {
                overflow: auto;
                height: initial;
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
