:root {
    --p-font-size: 1.6rem;
    --p-font-weight: 400;
    --h1-font-size: 6.4rem;
    --h2-font-size: 4.8rem;
    --h3-font-size: 3.2rem;
    --h4-font-size: 2.4rem;
    --h5-font-size: 1.6rem;
    --h6-font-size: 1.2rem;
    --theme-border-radius: 1rem;
    --theme-border-radius-button: 3.6rem;
    --header-font-weight: 500;
    --header-margin-top: 2.4rem;
    --header-margin-bottom: 1.2rem;
    --header-hero-font-size: 8rem;
    --header-line-height: 1.2;
    --narrow-content-width: 1000px;
    --header-logo-height: 60px;
    --header-logo-width: 60px;

    @include desktop {
        --header-nav-height: 80px;
        --header-height: var(--header-nav-height);
        --navigation-tabs-height: var(--header-height);
    }

    @include before-desktop {
        --header-height: 80px;
        --header-nav-height: 80px;
        --navigation-tabs-height: var(--header-height);

        @at-root .hiddenHeader {
            --header-height: 0px;
            --navigation-tabs-height: var(--header-height);
        }
    }

    @include mobile {
        --header-height: 50px;
        --header-nav-height: 50px;
        --navigation-tabs-height: var(--header-height);

        @at-root .hiddenHeader {
            --header-height: 0px;
            --navigation-tabs-height: var(--header-height);
        }
    }
}

body {
    letter-spacing: .02rem;
    color: var(--body-content-color);
    font-weight: var(--p-font-weight);
}

p {
    font-size: var(--p-font-size);
    margin-bottom: 2.4rem;
    line-height: 1.6;
}

li,
dl {
    font-size: var(--p-font-size);
}

h1 {
    font-size: var(--h1-font-size);
}

h2 {
    font-size: var(--h2-font-size);
}

h3 {
    font-size: var(--h3-font-size);
}

h4 {
    font-size: var(--h4-font-size);
}

h5 {
    font-size: var(--h5-font-size);
}

h6 {
    font-size: var(--h6-font-size);
}

h1,
h2,
h3,
h4,
h5,
h6,
h1 + h2,
h1 + h3,
h1 + h4,
h2 + h3,
h2 + h4,
h3 + h4 {
    font-weight: var(--header-font-weight);
    line-height: var(--header-line-height);
    color: var(--body-heading-color);
    top: 0;
    margin-top: 0;
    margin-bottom: var(--header-margin-bottom);
    text-transform: none;

    &.hero {
        font-size: var(--header-hero-font-size);
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    &:not(:first-child) { margin-top: var(--header-margin-top); }
}

// Zendesk chat window styling (global non-descript styles, not ideal)
body {
    & > iframe {
        & + div,
        & + div + div,
        & + div + div + div,
        & + div + div + div + div,
        & + div + div + div + div + div {
            &:not(#root):not(.Overlay):not(.Popup):not(.lm-base-root) {
                @include tablet {
                    width: 34rem!important;
                    bottom: 15rem!important;
                }
    
                @include desktop {
                    width: 34rem!important;
                }
            }
        }
    }

    & > div {
        &:not(#root):not(.Overlay):not(.Popup):not(.lm-base-root) {
            & > style + iframe {
                @include before-desktop {
                    left: 0.6rem!important;
                    bottom: 5.6rem!important;
                    z-index: 99!important;
                    transform: scale(0.74)!important;
                }
    
                @include tablet {
                    bottom: 8.4rem!important;
                }
            
                @include desktop {
                    left: 2.2rem!important;
                    bottom: 2.2rem!important;
                    z-index: 99!important;
                    transform: scale(0.9)!important;
                }
            }
        }
    }

    &.isMenuPage, 
    &.isCartPage,
    &.isCheckoutPage,
    &.isProductPage {
        > div {
            &:not(#root):not(.Overlay):not(.Popup):not(.lm-base-root) {
                & > style + iframe {
                    @include before-desktop {
                        display: none!important;
                    }
                }
            }
        }
    }

    &.isBuilderPage {
        & > iframe {
            & + div,
            & + div + div,
            & + div + div + div,
            & + div + div + div + div,
            & + div + div + div + div + div {
                &:not(#root):not(.Overlay):not(.Popup):not(.lm-base-root) {
                    @include desktop {
                        left: 9rem!important;
                        bottom: 9rem!important;
                    }
                }
            }
        }

        & > div {
            &:not(#root):not(.Overlay):not(.Popup):not(.lm-base-root) {
                & > style + iframe {
                    @include desktop {
                        bottom: 10rem!important;
                    }
                }
            }
        }
    }
}

// Cookie settings button (openli legal monster)
div.legalmonster-cleanslate.lm-base-root.lm-modal-root {
    z-index: 105 !important;

    & div.lm-shield-container {
        position: fixed !important;
        right: 2.4rem !important;
        bottom: 2.4rem !important;
        
        @include mobile {
            
            right: 1.8rem !important;
            bottom: 6.8rem !important;

            transform: scale(1.2) !important;
        }

        @include tablet {
            
            right: 0.6rem !important;
            bottom: 8.6rem !important;

            transform: scale(0.8) !important;
        }

        @at-root .isMenuPage &, .isCartPage &, .isCheckoutPage &, .isProductPage &, .isAccountPage & {
            @include before-desktop {
                display: none !important;
            }
        }

        @at-root .isBuilderPage & {
            display: none !important;
        }
    }

    & div.lm-container {
        @include tablet {
            position: fixed !important;
            right: 1.4rem !important;
            bottom: 9.4rem !important;
        }

        @include desktop {
            position: fixed !important;
            right: 2.4rem !important;
            bottom: 2.4rem !important;
        }
    }
}
