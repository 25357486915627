/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';

.CategoryPage:not(.CategoryPage_isBuilder) {
    .ProductCard {
        margin-bottom: 2.4rem;
        border: 1px solid var(--secondary-base-color);
        border-radius: var(--theme-border-radius);
        overflow: hidden;
        box-shadow: 0 0 0 0 transparent;
        top: 0;
        transition: all ease .3s;

        &-WishListButton {
            background: transparent;
        }
    
        &-Content {
            padding: 1.8rem;
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            background: var(--secondary-base-color);
        }

        &-Brand {
            font-size: 1.2rem;
            display: none;
        }

        &-Name {
            font-size: 1.8rem;
            line-height: 1.3;
            color: var(--body-heading-color);
            font-weight: 700;
            margin: 0;
            margin-bottom: 1.2rem;
        }

        &-Price {
            font-size: 1.4rem;
            font-weight: 500;
            color: var(--body-heading-color);
        }

        &-ConfigurableOptions {
            display: none;
        }

        &-Picture {
            padding-bottom: 100%;
            display: block;
        }

        &:hover {
            box-shadow: 0 3px 4px 1px rgba(0,0,0,0.1);
            top: -4px;
        }
    }
}

.CategoryPage_isBuilder {
    .ProductCard {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}
