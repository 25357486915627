/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../style/abstract/variables';
@import '../../../style/abstract/media';
@import '../../../style/abstract/button';
@import '../../../style/abstract/loader';
@import '../../../style/abstract/icons';
@import '../../../style/abstract/parts';
@import '../../../style/abstract/theme-abstract';

:root {
    --max-blog-width: 800px;
}

.PostsDetails {
    --total-header-height: calc(var(--header-menu-height) + var(--header-height) + 1px);

    background-color: white;

    @include desktop {
        margin-top: calc(var(--total-header-height) * -1);
    }

    &-Header {
        margin-top: -41px;

        section {
            width: 100%;
            top: 0;
            left: 0;
            background: linear-gradient(0deg, rgba(0, 0, 0, .4) 0%, rgba(0, 0, 0, 0) 85%);
        }

        .Image {
            padding: 0;
            height: 100%;
            width: 100%;
        }
    }

    &-HeaderWrapper {
        max-width: 100%;
        padding: 10rem 5rem 5rem 5rem;
        background-color: white;
    }

    &-HeaderContent {
        margin: 0 auto;
        max-width: var(--max-blog-width);
        color: black;

        @include mobile {
            padding: 0 2.4rem;
        }
    }

    &-Title {
        font-size: 4rem;
        color: black;
        text-align: left;
        margin-bottom: 10px;
        text-transform: unset;

        @include mobile {
            font-size: 2.4rem;
        }
    }

    &-HeaderMeta {
        padding-left: 5px;
        display: flex;
        flex-direction: row;

        // &::before {
        //     content: '';
        //     position: absolute;
        //     top: -10px;
        //     left: 0;
        //     width: 50px;
        //     height: 1px;
        //     background-color: rgba(255, 255, 255, .5);
        // }
    }

    &-HeaderBackLink {
        background: #d7282e;
        border-radius: 100%;
        display: block;
        height: 40px;
        width: 40px;
        position: fixed;
        z-index: 100;
        top: 115px;
        @include desktop {
            left: 10%;
        }
        @include tablet {
            top: 75px;
            left: 5%;
        }
        @include mobile {
            top: 75px;
            left: 5%;
        }

        &::before {
            content: '';
            background: url(icons/back.svg);
            background-size: cover;
            height: 30px;
            width: 15px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
            position: absolute;
            top: 50%;
            left: 45%;
            transform: translate(-50%, -50%);
        }
    }

    &-HeaderBackLinkText {
        display: none;
    }

    &-Author,
    &-Date {
        text-align: left;
        font-weight: 500;
        padding-right: 10px;
        font-size: 1.4rem;
    }

    &-ColumnWrapper {
        grid-gap: 50px;
        padding-top: 2.4rem;
        padding-bottom: 100px;
        flex-direction: column;
    }

    &-Grid {
        padding: 0;
        grid-template-columns: 1fr;
        grid-gap: var(--blog-grid-gap);
    }

    &-Description {
        flex-basis: 100%;
        width: 100%;
        padding-left: 0;
        max-width: var(--max-blog-width);
        margin: 0 auto;

        > .Image {
            margin-top: 1.2rem;
        }
    }

    &-Sidebar {
        flex-basis: 100%;
        width: 100%;
    }

    &-Content {
        margin-top: 2.4rem;
        color: black;

        h2 {
            font-size: 24px;
            font-weight: bold;
        }

        h3 {
            margin-top: 1.44rem !important;
            font-size: 22px;
            font-weight: bold;
        }

        p {
            font-size: 20px;
            line-height: 1.5;
        }

        .Image {
            margin: 0 auto;
            display: block;
            margin-top: 30px;
            margin-bottom: 30px;
            @include desktop {
                width: 100%;
            }
        }

        .Image-Image {
            position: relative;
            object-fit: cover;
            height: auto !important;
            display: block;
        }
    }
}
