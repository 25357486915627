/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';

.Popup {
    &:not(.ImageZoomPopup) {
        --popup-background: rgba(0, 0, 0, .7);
        --popup-header-height: 2.4rem;
        --popup-header-v-padding: 2.4rem;
        --popup-header-h-padding: 4.8rem;
        --popup-content-padding: 2.4rem 4.8rem;

        width: 100vw;
        height: 100vh;
        opacity: 0;
        transition: all ease .3s;

        @include mobile {
            height: 100vh;
            padding-top: 20vh;
        }

        .Popup-Content {
            overflow: hidden;
            padding: 0;
            height: auto;
            max-height: 80vh;    
            border-radius: var(--theme-border-radius);

            @include desktop {
                max-width: 800px;
            }

            @include tablet {
                max-width: 90vw;
                min-width: 0;
            }

            > *:not(.Popup-Header),
            .CmsBlock-Wrapper {
                height: auto;
                max-height: calc(80vh - var(--popup-header-v-padding)*2 - var(--popup-header-height));
                overflow: hidden;
                overflow-y: scroll;
                padding: var(--popup-content-padding);

            }
        }

        .Popup-Header {
            background: var(--primary-base-color);
            padding: var(--popup-header-v-padding) var(--popup-header-h-padding);

            @include before-desktop {
                display: block;
            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: url(https://www.larryvsharry.com/pub/media/hoeks-pwa/popup-header.png);
                background-size: cover;
                background-position: center;
                opacity: 0.1;
            }
        }

        .Popup-Heading {
            margin: 0;
            color: var(--white);
            font-weight: 500;
            height: var(--popup-header-height);
            line-height: var(--popup-header-height);
        }

        .Popup-CloseBtn { /* necessary nesting to overwrite Scandi */
            top: 50%;
            transform: translateY(-50%);
            right: 1.8rem;
            margin: 0;

            @include before-desktop {
                top: 50%;
                right: 1.8rem;
            }

            @include mobile {
                right: 3.6rem;
            }

            &::before,
            &::after {
                background-color: var(--white);
                width: 2px;
                height: 26px;
                top: 5px;
            }
        }

        &.Popup_isVisible {
            pointer-events: all;
            opacity: 1;
            width: 100vw;
            height: 100vh;
        }

        &.CmsPopup,
        &.ProductInfoPopup {
            &_isLarge {
                .Popup {
                    &-Content {
                        max-width: 90vw;
                        max-height: 90vh;
                        width: auto;
                        height: 90vh;

                        @include mobile {
                            max-height: 80vh;
                            height: 80vh;
                        }

                        .CmsBlock-Wrapper {
                            max-height: calc(90vh - var(--popup-header-v-padding)*2 - var(--popup-header-height));
                            height: calc(90vh - var(--popup-header-v-padding)*2 - var(--popup-header-height));

                            @include mobile {
                                max-height: calc(80vh - var(--popup-header-v-padding)*2 - var(--popup-header-height));
                                height: calc(80vh - var(--popup-header-v-padding)*2 - var(--popup-header-height));
                            }

                            .slick {
                                &-slider {
                                    height: calc(90vh - var(--popup-header-v-padding)*2 - var(--popup-header-height) - 4.8rem);

                                    @include mobile {
                                        height: calc(80vh - var(--popup-header-v-padding)*2 - var(--popup-header-height) - 4.8rem);
                                    }
                                }

                                &-list {
                                    height: 100%;
                                    width: 100%;
                                }

                                &-track {
                                    height: 100%;
                                    width: 100%;
                                }

                                &-slide {
                                    height: 100%;
                                    width: 100%;

                                    > div {
                                        height: 100%;
                                        width: 100%;
                                    }

                                    .SliderWidget-Figure {
                                        width: 100%!important;
                                        height: 100%!important;

                                        .SliderWidget-FigureImage {
                                            width: 100%!important;
                                            height: 100%!important;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;

                                            img {
                                                width: auto!important;
                                                height: auto!important;
                                                max-width: 100%;
                                                max-height: 100%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } 
    }
}
