/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
@import '../../../../../src/style/abstract/theme-abstract';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --blog-search-field-background: #{$default-secondary-base-color};
    --blog-search-field-border-color: #{$default-secondary-dark-color};
}

.BlogSearchBar {
    &-Search {
        @include search-icon;

        &::before {
            font-size: 12px;
            position: absolute;
            z-index: 1;
            top: 8px;
            right: 15px;
            content: '';
        }
    }

    &-SearchWrapper {
        flex-grow: 1;
        margin-right: 1rem;
        margin-left: 5px;
        text-align: right;
    }

    &-SearchField {
        font-size: 14px;
        width: 100%;
        max-width: 100%;
        padding: 4px 30px 5px 15px;
        transition-duration: 200ms;
        transition-property: border-radius;
        border-width: 1px;
        border-color: transparent;
        border-radius: 2rem;
        background-color: var(--blog-search-field-background);
        will-change: border-radius;

        &:focus {
            border-color: transparent;
            border-radius: 2rem 2rem 0 0;
        }
    }
}
